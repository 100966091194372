import useFetch from 'hooks/useFetch';
import SearchIcon from '@mui/icons-material/Search';

function AuditFilter({ events, onFilterChange }) {
    const [users] = useFetch('/users');

    const handleFilterChange = (ev) => {
        const { name, value } = ev.target;
        onFilterChange((prevFilters) => {
            const newFilters = { ...prevFilters };
            if (value === "") {
                delete newFilters[name];
            } else {
                newFilters[name] = value;
            }
            return newFilters;
        });
    };
    const handleFilterSearch = (ev) => {
        const { name, value } = ev.target;
        if (value.length >= 3 || value === "") {
            onFilterChange((prevFilters) => {
                const newFilters = { ...prevFilters };
                if (value === "") {
                    delete newFilters[name];
                } else {
                    newFilters[name] = value;
                }
                return newFilters;
            });
        }
    };
    return (
        <div className="flex w-full space-x-3 px-3 pb-2 rounded-lg justify-end">
            <div>
                <select name="event" onChange={handleFilterChange} className="px-2 py-1 border rounded text-sm">
                    <option value="">Event</option>
                    {events.map(event =>
                        <option key={event} value={event}>{event}</option>
                    )}
                </select>
            </div>
            <div>
                <select name="user_id" onChange={handleFilterChange} className="px-2 py-1 border rounded text-sm">
                    <option value="">Username</option>
                    {users !== null && users.map(user =>
                        <option key={user.id} value={user.id}>
                            {user.username}
                        </option>
                    )}
                </select>
            </div>
            <div className="relative px-2 py-1 border rounded text-sm w-48">
                <input name="keyword" type="text" onChange={handleFilterSearch} placeholder="Search Keyword..."
                    className="pl-2 pr-1 border-none outline-none rounded text-sm" />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
            <div className="relative px-2 py-1 border rounded text-sm w-48">
                <input name="client_ip" type="number" onChange={handleFilterSearch} placeholder="Search Client Ip..."
                    className="pl-2 pr-1 border-none outline-none rounded text-sm" />
                <SearchIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
        </div>
    );
}


export default AuditFilter